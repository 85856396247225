<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          lg="3"
          sm="3"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            label="Registros por página"
          >
            <v-select
            v-model="perPage"
            :options="pageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          sm="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group label="Seleccionar cajero"  >
              <v-select
                v-on:input="(cashierId) => {
                    this.cashierId = cashierId;
                    findCashierBalanceReportsByCashierIdApi();
                }"
                label="name"
                :options="cashiers"
                :reduce="cashier => cashier.cashierId"
                class="d-inline-block ml-50 mr-1"
                :clearable="false"
                style="width: 218px"
              />
          </b-form-group>
        </b-col>
        <b-col
          lg="3"
          sm="3"
          class="topTable"
        >
          <div class="d-flex align-items-right justify-content-end">
              <template
              v-if="cashierBalanceReports.length > 0"
              >
                <b-button
                    v-if="cashierBalanceReports[0].status === 'CLOSED'"
                    @click="showModal('refillMoneyDialog')">
                    Crear reporte
                  </b-button>
              </template>
              <template v-if="cashierBalanceReports.length === 0 && cashierId">
                  <b-button
                      @click="showModal('refillMoneyDialog')">
                      Crear reporte
                  </b-button>
              </template>

          </div>
        </b-col>
      </b-row>
      <hr style="margin-top: 15px">
    </div>
    <!-- :items="fetchInvoices" -->
    <b-overlay
      :show="tableOverlay"
      rounded="sm"
    >
      <b-table
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="cashierBalanceReports"
        @row-clicked="onRowClicked"
        ref="refInvoiceListTable"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No hay registros"
        class="position-relative"
      >
        <template #cell(createdAt)="data"> {{ new Date(data.item.createdAt) | moment("DD/MM/YYYY, h:mm a") }} </template>
        <template #cell(modifiedAt)="data"> {{ new Date(data.item.modifiedAt) | moment("DD/MM/YYYY, h:mm a") }} </template>
      </b-table>
    </b-overlay>
    <br>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>

      <b-modal
        id="refillMoneyDialog"
        title="Ingrese la cantidad ingresar"
        ok-title="Dinero ingresado"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @ok="createCashierBalanceReportApi()"
        @cancel=""
        centered
      >
        <h3 style="margin-bottom: 1em">
            Ajuste el dinero a ingresar y despues confirme que el dinero si esta ingresado
        </h3>
        <b-row
            v-for="(money, index) in refillMoney"
            :key="index"
        >
            <b-col lg="6" sm="6" class="mt-2">
                ${{money.denomination}}
            </b-col>
            <b-col lg="6" sm="6" class="mt-2">
                <b-form-input type="number" v-model="money.quantity"/>
            </b-col>
        </b-row>
      </b-modal>
    </div>
  </b-card>

</template>


<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/libs/customHelios';
</style>


<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BOverlay, BFormGroup, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import utils from '../../../utils/utils.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
      BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    ToastificationContent,
    BOverlay,
    vSelect,
    BFormGroup,
  },
  data() {
    return {
      tableColumns: [
        { key: 'shortCashierBalanceReportId', label: 'Report Id', sortable: false },
        { key: 'status', label: 'status', sortable: false },
        { key: 'createdAt', label: 'Creado', sortable: false },
        { key: 'modifiedAt', label: 'Modificado', sortable: false },
      ],
      perPage: 5,
      pageOptions: [5, 10, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'id',
      sortDesc: false,
      sortDirection: 'asc',
      refillMoney: [
          {"denomination": "1", "quantity": 0},
          {"denomination": "2", "quantity": 0},
          {"denomination": "5", "quantity": 0},
          {"denomination": "20", "quantity": 0},
          {"denomination": "50", "quantity": 0},

      ],
      cashierId: undefined,

      // Filters
      countryFilter: null,
      stateFilter: null,
      filtersArray: [null, null],
      cashiers: [],
      states: [],
      user: JSON.parse(localStorage.getItem("user")),

      // Data
      cashierBalanceReports: [],

      // Props
      tableOverlay: false,
    }
  },
  created() {
      this.findCashiersApi()
  },
  computed: {
  },
  methods: {
    findCashiersApi(){
        this.$axios.post(this.$services + "manager/findCashiersApi", {
            companyId: this.user.companyId,
        }).then(res => {
            if (!res.data.error) {
                this.cashiers = res.data;
            }
        }).catch(error => {
           this.showToast('danger', String(error));
        }).finally(() => {
        });
    },
    findCashierBalanceReportsByCashierIdApi() {
      this.tableOverlay = true
      this.$axios.post(this.$services + "manager/findCashierBalanceReportsByCashierIdApi", {
          cashierId: this.cashierId,
      }).then(res => {
          if (!res.data.error) {
              this.cashierBalanceReports = res.data;
              this.totalRows = res.data.length;
          }
      }).catch(error => {
        if (typeof error != 'string') {
        } this.showToast('danger', String(error));
      }).finally(() => {
        this.tableOverlay = false
      });
    },
    createCashierBalanceReportApi(){
      this.$axios.post(this.$services + "manager/createCashierBalanceReportApi", {
          cashierId: this.cashierId,
          refillMoney: this.refillMoney,
      }).then(res => {
          this.findCashierBalanceReportsByCashierIdApi()
      }).catch(error => {
          if (typeof error != 'string') {
          } this.showToast('danger', String(error));
      }).finally(() => {
          this.refillMoney = [
            {"denomination": "1", "quantity": 0},
            {"denomination": "2", "quantity": 0},
            {"denomination": "5", "quantity": 0},
            {"denomination": "20", "quantity": 0},
            {"denomination": "50", "quantity": 0},
          ]
      });
    },
    onRowClicked(cashierBalanceReport) {
        this.$router.push({ name: 'view-cashier-balance-report', params: { cashierBalanceReportId: cashierBalanceReport.cashierBalanceReportId }});
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    formatterNumber(value) {
      return utils.numberWithCommas(value);
    },
    showModal(modalName) {
        this.$bvModal.show(modalName);
    },
    hideModal(modalName) {
        this.$bvModal.hide(modalName);
    },
  },
}
</script>
